{
  "auth": {
    "agree": "Souhlasím s",
    "createAccount": "Vytvořit účet",
    "createNewAccount": "Vytvořit nový účet",
    "email": "Email",
    "login": "Přihlásit se",
    "password": "Heslo",
    "recover_password": "Obnovit heslo",
    "sign_up": "Zaregistrovat se",
    "keep_logged_in": "Zůstat přihlášený",
    "termsOfUse": "Podmínky užívání.",
    "reset_password": "Obnovit heslo"
  },
  "404": {
    "title": "Tato stránka je mimo provoz.",
    "text": "Pokud si myslíte, že to není správné, pošlete nám zprávu na ",
    "back_button": "Zpět na hlavní panel"
  },
  "typography": {
    "primary": "Primární textové styly",
    "secondary": "Sekundární textové styly"
  },
  "dashboard": {
    "versions": "Verze",
    "setupRemoteConnections": "Nastavení vzdálených připojení",
    "currentVisitors": "Aktuální návštěvníci",
    "navigationLayout": "rozložení navigace",
    "topBarButton": "Horní lišta",
    "sideBarButton": "Boční lišta"
  },
  "language": {
    "brazilian_portuguese": "Portugalština",
    "english": "Angličtina",
    "spanish": "Španělština",
    "simplified_chinese": "Zjednodušená čínština",
    "persian": "Perština"
  },
  "menu": {
    "auth": "Autorizace",
    "buttons": "Tlačítka",
    "timelines": "Časové osy",
    "dashboard": "Hlavní panel",
    "billing": "Fakturace",
    "login": "Přihlásit se",
    "preferences": "Předvolby účtu",
    "payments": "Platby",
    "settings": "Nastavení aplikace",
    "pricing-plans": "Cenové plány",
    "payment-methods": "Platební metody",
    "signup": "Zaregistrovat se",
    "recover-password": "Obnovit heslo",
    "recover-password-email": "Email pro obnovení hesla",
    "404": "404",
    "faq": "FAQ",
    "users": "Uživatelé",
    "projects": "Projekty",
    "faults": "Chybové hlášky"
  },
  "messages": {
    "all": "Zobrazit všechny zprávy",
    "new": "Nové zprávy od {name}",
    "mark_as_read": "Označit jako přečtené"
  },
  "navbar": {
    "messageUs": "Dotazy na vývoj webu:",
    "repository": "GitHub Rep"
  },
  "notifications": {
    "all": "Zobrazit všechny upozornění",
    "less": "Zobrazit méně upozornění",
    "mark_as_read": "Označit jako přečtené",
    "sentMessage": "vám poslal(a) zprávu",
    "uploadedZip": "nahrál(a) nový soubor typu Zip s {type}",
    "startedTopic": "zahájil(a) nové téma"
  },
  "user": {
    "language": "Změnit jazyk",
    "logout": "Odhlásit se",
    "profile": "Profil",
    "settings": "Nastavení",
    "billing": "Fakturace",
    "faq": "FAQ",
    "helpAndSupport": "Nápověda a podpora",
    "projects": "Projekty",
    "account": "Účet",
    "explore": "Prozkoumat"
  },
  "treeView": {
    "basic": "Základní",
    "icons": "Ikony",
    "selectable": "Vybratelné",
    "editable": "Editovatelné",
    "advanced": "Pokročilé"
  },
  "chat": {
    "title": "Chat",
    "sendButton": "Odeslat"
  },
  "spacingPlayground": {
    "value": "Hodnota",
    "margin": "Okraj",
    "padding": "Odsazení"
  },
  "spacing": {
    "title": "Rozestupy"
  },
  "cards": {
    "cards": "Karty",
    "fixed": "Pevné",
    "floating": "Plovoucí",
    "contentText": "Unikátní pruhy zeber z nich činí jedno z nejznámějších zvířat.",
    "contentTextLong": "Unikátní pruhy zeber z nich činí jedno z nejznámějších zvířat. Vyskytují se v různých biotopech, jako jsou savany, lesy, keřové porosty, hory a pobřežní kopce. Různé lidské aktivity měly závažný dopad na populace zeber, zejména lov pro kůži a ničení biotopů. Grévyho zebra a horská zebra jsou ohrožené. I když jsou zebry plání mnohem hojnější, jeden poddruh, quagga, vyhynul.",
    "rowHeight": "Výška řádku",
    "title": {
      "default": "Výchozí",
      "withControls": "S ovládacími prvky",
      "customHeader": "Vlastní záhlaví",
      "withoutHeader": "Bez záhlaví",
      "withImage": "S obrázkem",
      "withTitleOnImage": "S titulkem na obrázku",
      "withCustomTitleOnImage": "S vlastním titulkem na obrázku",
      "withStripe": "S pruhem",
      "withBackground": "S pozadím"
    },
    "button": {
      "main": "Hlavní",
      "cancel": "Zrušit",
      "showMore": "Zobrazit více",
      "readMore": "Přečíst více"
    },
    "link": {
      "edit": "Upravit",
      "setAsDefault": "Nastavit jako výchozí",
      "delete": "Smazat",
      "traveling": "Cestování",
      "france": "Francie",
      "review": "Recenze",
      "feedback": "Zanechat zpětnou vazbu",
      "readFull": "Přečíst celý článek",
      "secondaryAction": "Sekundární akce",
      "action1": "Akce 1",
      "action2": "Akce 2"
    }
  },
  "colors": {
    "themeColors": "Barvy tématu",
    "extraColors": "Extra barvy",
    "gradients": {
      "basic": {
        "title": "Gradienty tlačítek"
      },
      "hovered": {
        "title": "Hoverované gradienty tlačítek",
        "text": "Pro hover stav se aplikuje zesvětlení o 15 % původního stylu (gradient nebo plochá barva)."
      },
      "pressed": {
        "title": "Zmáčknuté gradienty tlačítek",
        "text": "Pro stisknutý stav se aplikuje ztmavení o 15 % původního stylu (gradient nebo plochá barva)."
      }
    }
  },
  "tabs": {
    "alignment": "Zarovnání záložek",
    "overflow": "Přetékání záložek",
    "hidden": "Záložky se skrytým posuvníkem",
    "grow": "Růst záložek"
  },
  "helpAndSupport": "Nápověda a podpora",
  "aboutVuesticAdmin": "O Vuestic Admin",
  "search": {
    "placeholder": "Hledat..."
  },
  "buttonSelect": {
    "dark": "Tmavý",
    "light": "Světlý"
  },
  "custom": {
    "language": "Jazyk",
    "turn_off_inverter": "Vypnout systém",
    "turn_on_inverter": "Zapnout systém",
    "regulation_active": "Regulace aktivní",
    "regulation_inactive": "Regulace neaktivní",
    "emergency_stop": "Nouzové zastavení",
    "force_battery_charge": "Nucené nabíjení baterie",
    "set_charge": "Nastavit Nabití",
    "actual_regulation_state": "Aktuální stav regulace",
    "actual_calculated_regulation": "Aktuální vypočtená regulace",
    "total": "Celkem",
    "total_active_power": "Celkový aktivní výkon",
    "l1_active_power": "L1 aktivní výkon",
    "l2_active_power": "L2 aktivní výkon",
    "l3_active_power": "L3 aktivní výkon",
    "l1_voltage": "L1 napětí",
    "l2_voltage": "L2 napětí",
    "l3_voltage": "L3 napětí",
    "pt": "PT",
    "pa": "PA",
    "pb": "PB",
    "pc": "PC",
    "phase_1_current": "Fáze 1 proud",
    "phase_2_current": "Fáze 2 proud",
    "phase_3_current": "Fáze 3 proud",
    "charts": "Grafy",
    "compound": "Areál",
    "current_per_phase": "Aktuální proud na fázi",
    "total_active_power_chart": "Graf celkového aktivního výkonu",
    "regulation_chart": "Graf regulace",
    "inverter_faults": "Chybové hlášky",
    "module_temperature": "Teplota modulu",
    "ambient_temperature": "Okolní teplota"
  }
}
