import { defineStore } from 'pinia'
import { ref, Ref } from 'vue'

export interface Measurement {
  value: number | null | boolean
  unit: string
}

export interface DeviceMeasurements {
  [key: string]: Measurement
}
export interface RegulationStatus {
  [key: string]: Measurement
}

export interface DataItem {
  smart_meter: DeviceMeasurements
  inverter: DeviceMeasurements
  regulation: RegulationStatus
}

export const useInverterStore = defineStore('inverterStore', () => {
  const data: Ref<DataItem> = ref({
    smart_meter: {
      Pt: { value: null, unit: 'kW' },
      Pa: { value: null, unit: 'kW' },
      Pb: { value: null, unit: 'kW' },
      Pc: { value: null, unit: 'kW' },
      'Phase 1 current': { value: null, unit: 'A' },
      'Phase 2 current': { value: null, unit: 'A' },
      'Phase 3 current': { value: null, unit: 'A' },
    },
    inverter: {
      'Total active power': { value: null, unit: 'kW' },
      'L1 Active power': { value: null, unit: 'kW' },
      'L2 Active power': { value: null, unit: 'kW' },
      'L3 Active power': { value: null, unit: 'kW' },
      'L1 Voltage': { value: null, unit: 'V' },
      'L2 Voltage': { value: null, unit: 'V' },
      'L3 Voltage': { value: null, unit: 'V' },
    },
    regulation: {
      'Regulation status': { value: false, unit: '' },
      'Regulation power': { value: null, unit: 'Kw' },
    },
  })

  let socket: WebSocket | null = null

  const connectWebSocket = () => {
    const source_ip = window.location.hostname
    // const source_ip = 'irebox.manosens.com'
    // const source_port = 8000
    const source_ws = 'wss://' + source_ip + '/ws'
    socket = new WebSocket(source_ws)

    socket.onmessage = (event) => {
      const newData: DataItem = JSON.parse(event.data)
      // Môžete pridať logiku na aktualizáciu konkrétnych hodnôt podľa prichádzajúcich dát
      data.value.smart_meter = newData.smart_meter
      data.value.regulation = newData.regulation
      data.value.inverter = newData.inverter
    }

    socket.onerror = (error) => {
      console.error('WebSocket error:', error)
    }

    socket.onclose = () => {
      console.log('WebSocket connection closed. Reconnecting...')
      setTimeout(connectWebSocket, 1000) // Znovu pripojenie po 1 sekunde
    }
  }

  return {
    data,
    connectWebSocket,
  }
})
