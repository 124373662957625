import { defineStore } from 'pinia'
import { ref, Ref } from 'vue'

interface StatusValues {
  [value: number]: string
}

// Define the structure of a single status bit
interface StatusBit {
  description: string
  status: number // Typically 0 or 1
  type: string
  values?: StatusValues
}

// Define the structure for an entire registry, which contains multiple bits
interface StatusRegistry {
  [bitKey: string]: StatusBit
}

// Store structure
interface StatusStoreState {
  [registryId: number]: StatusRegistry
}

export const useFaultsStore = defineStore('faultsStore', () => {
  const data: Ref<StatusStoreState> = ref({})

  let socket: WebSocket | null = null

  const connectWebSocket = () => {
    const source_ip = window.location.hostname
    // const source_ip = 'irebox.manosens.com'
    // const source_port = 8000
    const source_ws = 'wss://' + source_ip + '/ws-faults'
    socket = new WebSocket(source_ws)

    socket.onmessage = (event) => {
      const newData: StatusStoreState = JSON.parse(event.data)
      // You can add logic to update specific values based on incoming data
      data.value = newData
    }

    socket.onerror = (error) => {
      console.error('WebSocket error:', error)
    }

    socket.onclose = () => {
      console.log('WebSocket connection closed. Reconnecting...')
      setTimeout(connectWebSocket, 1000) // Znovu pripojenie po 1 sekunde
    }
  }

  return {
    data,
    connectWebSocket,
  }
})
